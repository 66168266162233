
import UserImgInfo from '@/components/ui/molecule/UserImgInfo';
import SocialInteraction from '@/components/ui/molecule/SocialInteraction';
import MultipleImageDisplay from '@/components/ui/molecule/MultipleImageDisplay';
import { generateUID } from '@/utils';
import UIPost from '@/components/ui/organism/UIPost';
import { getMedia, isMobileResponsive } from '@/utils/mixins';
import PostMore from '@/components/ui/molecule/PostMore';
import TruncateParagraph from '@/components/ui/atom/TruncateParagraph';
import Embed from '@/components/ui/molecule/Embed';
import PollVote from '@/components/ui/molecule/PollVote';
import UIPortfolioCard from '@/components/ui/molecule/PortfolioCard/UIPortfolioCard';
import { inAppRedirect } from '~/utils/route-redirects';
import { appURLs } from '@/utils/route-redirects';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'UIProfilePost',
  components: {
    Embed,
    UserImgInfo,
    PostMore,
    UIPost,
    PollVote,
    SocialInteraction,
    MultipleImageDisplay,
    TruncateParagraph,
    UIPortfolioCard,
  },
  mixins: [getMedia, isMobileResponsive],
  props: {
    post: {
      type: Object,
      default: () => null,
    },
    id: {
      type: String,
      default: '',
    },
    isReply: {
      type: Boolean,
      default: false,
    },

    disableHover: {
      type: Boolean,
      default: false,
    },
    body: {
      type: String,
      default: '',
    },
    media: {
      type: Array,
      default: () => [],
    },
    showSocialInteraction: {
      type: Boolean,
      default: true,
    },
    isSocialInteractionDisabled: {
      type: Boolean,
      default: false,
    },
    showOptions: {
      type: Boolean,
      default: true,
    },
    showCard: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      htmlBody: '',
      mentionIds: [],
      mentionedPost: null,
      appURLs,
      portfolio: null,
    };
  },
  computed: {
    ...mapGetters('portfolio', ['getPortfolioByIdRes']),
    ...mapGetters('authentication', ['profileId']),
    fromNow() {
      const fromNow = this.$dayjs(this.post.createdDate)
        .subtract('1', 's')
        .startOf('s')
        .fromNow();
      return fromNow;
    },
    // TODO refactor all instancesd of this.$route.name in computed to handle the name being null in case the user types a route that doesn't exist
    isMobile() {
      return window.screen.width <= 640;
    },
  },
  watch: {
    body() {
      this.buildBody();
    },
  },
  beforeCreate: function () {
    this.$options.components.UIPost =
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      require('@/components/ui/organism/UIPost').default;
  },
  created() {
    this.buildBody();
    this.getPostPortfolio();
  },

  mounted() {
    this.bindBodyEvent();
  },
  beforeDestroy() {
    this.removeBodyEvent();
  },
  methods: {
    ...mapActions('portfolio', [
      'getPortfolioById',
      'likePortfolio',
      'favoritePortfolio',
    ]),
    buildBody() {
      //TODO : TBD : This is handled in the API when creating the news post, still there for old news posts
      this.htmlBody = this.body;

      this.removeBodyEvent();

      if (this.post.mentions && this.post.mentions.length) {
        this.post.mentions.forEach((mention) => {
          if (mention.type === '@') {
            const id = generateUID();
            const profilePath = `/${appURLs.profile()}/${
              mention.profile.profileId
            }`;
            this.htmlBody = this.htmlBody.replaceAll(
              `${mention.type}${mention.target}`,
              `<span class="${id} mention cursor-pointer" data-id="${mention.profile.profileId}"><a data-id="${mention.profile.profileId}" href="${profilePath}">${mention.type}${mention.profile.displayName}</a></span>`
            );
            this.mentionIds.push(id);
          } else if (mention.type === '#') {
            this.htmlBody = this.htmlBody.replaceAll(
              `${mention.type}${mention.target}`,
              `<a href="/search-hashtags?hashtag=${mention.target}" class="mention cursor-pointer">${mention.type}${mention.target}</a>`
            );
          } else if (mention.type === 'POST') {
            this.mentionedPost = mention.post;
          }
        });
      }
    },
    handleMouseEnterBodyMention(e) {
      if (e && e.target) {
        const profileMentioned = this.post.mentions.find(
          (elem) => elem.target === e.target.dataset.id
        );

        this.$emit('mouseEnterMention', {
          pos: {
            top: e.target.offsetTop + (e.target.offsetHeight + 8),
            left: e.target.offsetLeft,
          },
          profile: profileMentioned.profile,
          id: e.target.dataset.id,
        });
      }
    },
    handleMouseOutBodyMention(e) {
      if (e && e.target) {
        this.$emit('mouseOutMention', e.target.dataset.id);
      }
    },
    bindBodyEvent() {
      if (this.isMobile) {
        // We don't want mention mention event attached on mobile
        return;
      }
      this.mentionIds.forEach((id) => {
        const mentionNodes = document.getElementsByClassName(id);
        for (const node of mentionNodes) {
          node.addEventListener('mouseenter', this.handleMouseEnterBodyMention);
          node.addEventListener('mouseout', this.handleMouseOutBodyMention);
          node.addEventListener('click', this.handleMouseOutBodyMention, true);
        }
      });
    },
    removeBodyEvent() {
      this.mentionIds.forEach((id) => {
        const mentionNodes = this.$refs.body.getElementsByClassName(id);

        for (const node of mentionNodes) {
          node.removeEventListener(
            'mouseenter',
            this.handleMouseEnterBodyMention
          );
          node.removeEventListener('mouseout', this.handleMouseOutBodyMention);
          node.removeEventListener(
            'click',
            this.handleMouseOutBodyMention,
            true
          );
        }
      });
      this.mentionIds = [];
    },
    goToProfile(profileId, externalUri = null) {
      if (externalUri) {
        window.open(externalUri);
      } else {
        inAppRedirect(`/profile/${profileId}`);
      }
    },
    goToPost(postId) {
      inAppRedirect(`/post/${postId}`);
    },
    handlePortfolioTagClick(portfolioId) {
      inAppRedirect(
        this.localePath({
          path: `/portfolios/${portfolioId}`,
          query: { topic: 'conversation' },
        })
      );
    },
    handleCompanyTagClick(profileId) {
      inAppRedirect(
        this.localePath({
          path: `/profile/${profileId}`,
          query: { topic: 'conversation' },
        })
      );
    },
    getProfilePath(profileId) {
      return this.localePath(`/${appURLs.profile()}/${profileId}`);
    },
    async getPostPortfolio() {
      if (this.post?.portfolioId) {
        await this.getPortfolioById(this.post.portfolioId);
        this.portfolio = this.getPortfolioByIdRes.data;
      }
    },
    handlePortfolioLike(id) {
      this.likePortfolio(id);
    },
    handleProfileClick(id) {
      this.$emit('onClose');
      inAppRedirect(`/profile/${id}`);
    },
    handlePortfolioClick(id) {
      this.$emit('onClose');
      inAppRedirect(`/portfolios/${id}`);
    },
    handleFavoritePortfolio(id) {
      this.favoritePortfolio(id);
    },
    // Allow handlers only on the post page
    handleCardClick(event) {
      if (this.$route.path.includes('post')) {
        switch (event) {
          case 'like':
            this.handlePortfolioLike(this.portfolio.id);
            break;
          case 'favorite':
            this.handleFavoritePortfolio(this.portfolio.id);
            break;
          case 'portfolio':
            this.handlePortfolioClick(this.portfolio.id);
            break;
          case 'profile':
            this.handleProfileClick(this.portfolio.owner.ownerId);
            break;
          case 'comment':
            this.handlePortfolioTagClick(this.portfolio.id);
            break;
        }
      } else this.$emit('goToPost');
    },
  },
};
