import localizeOrdinal from './services/ordinal';

export default {
  CommunicationServices: 'Télécommunication',
  ConsumerDiscretionary: 'Biens de consommation discrétionnaires',
  ConsumerStaples: 'Biens de consommation',
  Energy: 'Energie',
  Financials: 'Finance',
  Healthcare: 'Santé',
  Industrials: 'Industrie',
  InformationTechnology: 'Technologie',
  Materials: 'Matériaux',
  Miscellaneous: 'Divers',
  RealEstate: 'Immobilier',
  Utilities: 'Utilitaires',
  General: 'Général',
  Cryptocurrencies: 'Cryptomonnaies',
  Cryptocurrency: 'Cryptomonnaie',
  companies: 'Sociétés',
  auto_generated: 'Généré-automatiquement',
  onboarding_tour_profile_desc:
    'Voici votre profil. Modifiez-le comme vous voulez !',
  onboarding_tour_portfolio_desc:
    'Créez votre premier portefeuille ici et suivez vos sociétés préférées  !',
  onboarding_tour_settings_desc:
    "Vous pouvez choisir vos centres d'interêt dans vos paramètres",
  onboarding_tour_market_desc: "Suivez l'actualité des marchés ici !",
  onboarding_tour_crypto_desc: "Suivez l'actualité des cryptomonnaies ici !",
  onboarding_birthdate_title: ', bienvenue sur Skade !',
  onboarding_birthdate_info:
    'Voici quelques étapes pour compléter votre compte. <br /> Vous pouvez sauter ces étapes ou revenir les compléter plus tard.',
  onboarding_birthdate_label: 'Nous avons besoin de votre date de naissance.',
  onboarding_sector_title: 'Merci ! Votre compte est prêt.',
  onboarding_sector_subtitle:
    'Les secteurs qui vous intéressent (en choisir 3 ou plus):',
  onboarding_sector_info:
    'Voici quelques étapes pour compléter votre compte. <br /> Vous pouvez sauter ces étapes ou revenir les compléter plus tard.',
  onboarding_company_title: 'Vos actualités personnalisées',
  follow_companies_description:
    'En suivant des sociétés, vous recevrez leur actualité en priorité !',
  follow_companies_additional:
    "L'actualité des sociétés apparaîtra sur  votre page d'accueil.",
  onboarding_user_title: 'Votre nouvelle communauté',
  onboarding_user_info_title:
    'Rejoignez la communauté en commençant à suivre d’autres skaders !',
  onboarding_user_info_subtitle:
    "Les posts des comptes que vous suivez apparaîtront sur votre page d'accueil.",
  onboarding_question_1: 'Apprenons à nous connaître.',
  onboarding_question_1_choice_1: 'Je n’ai jamais investi',
  onboarding_question_1_choice_2: 'Je débute en investissement',
  onboarding_question_1_choice_3: 'Je suis familier avec l’investissement',
  onboarding_question_1_choice_4: 'Je suis un investisseur expérimenté',
  onboarding_question_1_choice_5: 'Autre',
  onboarding_question_2: 'Qu’attendez-vous de skade ?',
  onboarding_question_2_choice_1: 'Apprendre à investir en bourse',
  onboarding_question_2_choice_2: 'Apprendre à investir en crypto',
  onboarding_question_2_choice_3: 'Suivre mes investissements',
  onboarding_question_2_choice_4: 'Echanger avec d’autres investisseurs',
  onboarding_question_2_choice_5: 'Autre',
  multiple_choice_allowed: 'Plusieurs choix sont possibles',
  skip: 'Passer',
  suggested: 'Suggéré',
  suggested_accounts: 'Suggéré',
  top_investors: 'Top investisseurs',
  view_more: 'Voir plus…',
  see_more: 'Voir',
  us_market_open: 'Les marchés américains sont ouverts',
  trade: 'Transaction',
  actions: 'Actions',
  shares_repartition: 'Répartition',
  return: '± value',
  remove_your_portfolio: 'Supprimer le portefeuille',
  remove_portfolio: 'Supprimer le portefeuille',
  portfolio_public_help: '(valeurs en pourcentages uniquement)',
  portfolio_private_help: '(vu par moi uniquement)',
  portfolio_is_listed_help:
    '(votre portefeuille entrera en compétition après validation)',
  portfolio_privacy_settings_public_help:
    'Votre portefeuille est public et est en compétition avec les portefeuilles des autres utilisateurs',
  portfolio_privacy_settings_unlisted_help: `Votre portefeuille est actuellement public et vous pouvez le partager, mais il n'est pas classé, et n'entrera en compétition que {timer}`,
  portfolio_privacy_settings_private_help: `Votre portefeuille est actuellement privé et n'est visible que par vous`,
  portfolio_get_listed_modal_title: 'Entrer dans la compétition',
  portfolio_get_listed_modal_text: `Votre portefeuille peut maintenant entrer en compétition avec ceux des autres utilisateurs, et recevoir son rang global. Vous ne pourrez plus y ajouter de transactions datant de plus de 5 jours.
  Souhaitez-vous continuer?`,
  portfolio_get_listed_modal_submit: `Entrer en compétition`,
  portfolio_get_listed_modal_later: `Me le rappeler plus tard`,
  portfolio_get_listed_modal_cancel: `Rester hors compétition`,
  portfolio_privacy_change_modal_submit: 'Confirmer',
  portfolio_privacy_change_modal_private_to_public_cancel: 'Rester privé',
  portfolio_privacy_change_modal_public_to_private_cancel: 'Rester public',
  portfolio_privacy_change_modal_private_to_public_body: `Cette action rendra votre portefeuille public et non répertorié. Vous pourrez en partager le lien à d’autres utilisateurs.
  Après 1 mois, vous pourrez choisir de participer aux compétitions de la communauté si vous respectez les critères de classement.
  Voulez-vous continuer ?`,
  portfolio_privacy_change_modal_public_to_private_body: `Cette action rendra votre portefeuille privé. Vous ne pourrez plus le partager à d'autres utilisateurs. Voulez-vous continuer?`,
  portfolio_privacy_change_modal_title: 'Options de confidentialité',
  portfolio_privacy_info_bubble_title: 'Confidentialité de votre portefeuille',
  portfolio_privacy_info_bubble_title_1:
    'Quels sont les différents niveaux de confidentialité ?',
  portfolio_privacy_info_bubble_title_2:
    'Comment modifier le niveau de confidentialité?',
  portfolio_privacy_info_bubble_title_3:
    'Comment faire partie des classements de la communauté?',
  portfolio_privacy_info_bubble_p1_bp1:
    'Privé : Par défaut, tous les portefeuilles sont privés. Uniquement le propriétaire du portefeuille peut y accéder.',
  portfolio_privacy_info_bubble_p1_bp2:
    'Non répertorié : Avec ce statut, vous pouvez partager le lien url de votre portefeuille à quiconque sur skade. Uniquement des données en % seront affichées aux utilisateurs autres que vous.',
  portfolio_privacy_info_bubble_p1_bp3:
    'Public et classé : Votre portefeuille est inclus dans les classements de la communauté et peut être vu par tous sur skade. Uniquement des données en % seront affichées aux utilisateurs autres que vous.',
  portfolio_privacy_info_bubble_p2:
    'Vous pouvez modifier ce paramètre en cliquant sur le bouton de confidentialité sur la page de votre portefeuille, ou dans les paramètres de vos portefeuilles.',
  portfolio_privacy_info_bubble_p3: `Tout d'abord, selectionnez le niveau de confidentialité Public. Vous devrez ensuite remplir ces trois critères `,
  portfolio_privacy_info_bubble_p3_bp1:
    'Être Public depuis au moins une semaine',
  portfolio_privacy_info_bubble_p3_bp2: `Ne pas avoir d'opérations antérieures à 48 heures dans le portefeuille concerné`,
  portfolio_privacy_info_bubble_p3_bp3: 'Avoir plus de 5 tickers',
  portfolio_public_view_info_bubble_title: 'Vue complète ou publique',
  portfolio_public_view_info_bubble_body:
    "La vue complète n'est visible que par vous. La vue publique vous donne un aperçu de ce que les autres utilisateurs verraient en visitant votre portefeuille.",
  portfolio_ranking_info_bubble_title: 'Comment obtenir un classement ?',
  portfolio_ranking_info_bubble_p1:
    'Parce que nous voulons transmettre les bonnes pratiques, vous devrez respecter les critères suivants afin d’obtenir un classement :',
  portfolio_ranking_info_bubble_bp1: 'Minimum 5 lignes d’investissement',
  portfolio_ranking_info_bubble_bp2:
    'Status public du portefeuille depuis 1 semaine',
  portfolio_ranking_info_bubble_p2:
    'Attention : Une fois votre portefeuille public, vous ne pourrez plus réaliser d’opération antérieure à 48 heures. ',
  portfolio_transaction_type_modal_title: 'Nouvelle opération',
  portfolio_transaction_type_modal_body: "Type d'opération",
  portfolio_transaction_type_modal_submit: 'Continuer',
  portfolio_graph_marker_enter_ranking: 'Entrée en compétition',
  coming_soon: 'Bientôt disponible',
  private_mode_coming_soon: 'Mode privé bientôt disponible',
  delete: 'Supprimer',
  evolution: 'Evolution',
  rename_portfolio: 'Renommer le portefeuille',
  portfolio_update_ok: 'Le portefeuille a bien été mis à jour',
  add_to: 'Ajouter à',
  are_you_sure_remove: 'Voulez-vous vraiment le supprimer ?',
  add_operation: 'Ajouter une transaction',
  transactions_history: 'Historique',
  operation_type: 'Type de transaction',
  transactions: 'Transactions',
  performance: 'Performance',
  portfolio_appearances: 'Apparences dans les portefeuilles',
  portfolio_card_latest_title: 'Variation',
  portfolio_card_stock_change_title: 'Stock Change',
  stock_symbol_type_tag: 'Action',
  etf_tag: 'ETF',
  allocation: 'Répartition',
  allocations: 'Répartition',
  sectors: 'Secteurs',
  sector: 'Secteur',
  industryCap: 'Industrie',
  add_symbol_to: 'Ajouter à',
  of: 'de',
  buy: 'Acheter',
  sell: 'Vendre',
  type: 'Type',
  shared: 'Partagé',
  amount: 'Montant',
  portfolio_value: 'Valeur du portfolio',
  us_market_are: 'Les marchés américains sont',
  eu_market_are: 'Les marchés européens sont',
  is_public: 'Publique ?',
  is_public_explain:
    'Vue publique (uniquement les pourcentages seront visibles)',
  trading_fee: 'Frais de transaction',
  trading_fee_explain:
    'Frais que votre intermédiaire financier prélève à chaque transaction',
  open: 'ouverts',
  closed: 'fermés',
  item_add_ok: 'Ce symbole a bien été ajouté à votre portefeuille',
  operation_add_ok: 'Cette opération a bien été ajoutée à votre portefeuille',
  operation_add_error_no_shares_at_date: 'Aucune action à vendre à cette date ',
  operation_add_error_not_enough_cash: 'Liquidités insuffisantes',
  operation_add_error_general: "Votre transaction n'a pas été ajoutée ",
  operation_transaction_error_message: 'Erreur de transaction',
  welcome_card: 'Bienvenue !',
  body_on_welcome_card:
    "Commencez à suivre plus d'utilisateurs et de sociétés !",
  suggestions: 'Suggestions',
  view_suggestions: 'Voir des suggestions',
  login_welcome: 'Bienvenue sur Skade',
  login_right_title: 'Votre réseau social financier',
  get_started: "S'inscrire",
  login: 'Se connecter',
  sign_in: 'Se connecter',
  sign_in_title: 'Connectez-vous à votre compte',
  login_credentials: 'Entrez vos identifiants pour accéder à votre compte',
  not_yet_register: "Vous n'avez pas de compte ?",
  already_registered: 'Vous avez déjà un compte ?',
  create_an_account: 'Créez-en un',
  create_account: 'Créez un compte',
  forgot_password: 'Mot de passe oublié ?',
  sign_up: "S'inscrire",
  sign_up_title: 'Créer un nouveau compte',
  register_credentials:
    "Vous n'avez pas de compte ? Créez votre compte en quelques minutes !",
  register_with_email: "S'incrire par e-mail",
  username_taken: "Ce nom d'utilisateur est déjà pris",
  select_one_symbol_min: 'Sélectionnez au moins un ticker.',
  invalid_credential: 'Email ou mot de passe incorrect.',
  oops_error: 'Oups ! Il y a une erreur.',
  loading: 'Chargement…',
  add: 'Ajouter',
  remove: 'Retirer',
  decrease: 'Diminuer',
  withdraw: 'Retirer',
  add_post_favorite: 'Ajouter le post aux favoris',
  remove_post_favorite: 'Supprimer le post des favoris',
  new_post_to_load: 'Voir {count} nouveaux posts',
  feed: 'Flux',
  home: 'Accueil',
  first_name: 'Prénom',
  last_name: 'Nom de famille',
  birthdate: 'Date de naissance',
  date: 'Date',
  creation_date: 'Date de création',
  time: 'Heure',
  cancel: 'Annuler',
  confirm: 'Confirmer',
  posts: 'Posts',
  news: 'Actualités',
  profile: 'Profil',
  groups: 'Groupes',
  market: 'Marchés',
  sidebar_market: 'Actualités',
  crypto: 'Cryptomonnaies',
  change_profile: 'Changer de profil',
  users: 'Utilisateurs',
  hashtags: 'Hashtags',
  message: 'Message',
  messages: 'Messages',
  likes: 'Likes',
  more: 'Plus',
  close: 'Fermer',
  upload_picture: 'Choisir un fichier',
  price_between: 'Prix autorisé entre {min} et {max}',
  asset_quantity_available: '{quantity} parts disponibles à cette date',
  not_enough_shares: 'Pas assez de parts à vendre à cette date',
  browser_do_note_support:
    'Désolé, votre navigateur ne prend pas en charge cette fonctionnalité.',
  next: 'Suivant',
  continue: 'Continuer',
  got_it: "J'ai compris",
  back: 'Retour',
  finish: 'Terminer',
  settings: 'Réglages',
  export: 'Exporter',
  share: 'Partager',
  last_30_day: 'Derniers 30 jours',
  today: "Aujourd'hui",
  global: 'Général',
  market_value: 'Valeur actuelle',
  overall_return: '± value',
  portfolio_cost: "Valeur d'achat",
  my_portfolios: 'Mes Portefeuilles',
  my_shared_portfolios: 'Portefeuilles partagés avec moi',
  my_shared_portfolios_short: 'Partagés avec moi',
  community_portfolios: 'Portefeuilles de la communauté',
  monthly_top_gainers_portfolios: 'Top portefeuilles - ',
  global_rank: 'Classement global',
  league_rank: 'Classement de ligue',
  total_cost: "Valeur d'achat",
  total_value: 'Valeur actuelle',
  total_return: '± value',
  my_favorite_portfolio: 'Mes Portefeuilles favoris',
  my_favorite_portfolio_short: 'Favoris',
  value: 'Valeur',
  cost: 'Coût',
  gain: 'Gain',
  price: 'Prix',
  total_price: 'Prix total',
  total_amount: 'Montant total',
  total: 'Total:',
  unit_price: 'Prix unitaire',
  token_price: 'Prix par token',
  share_price: 'Prix par action',
  portfolio: 'Portefeuille',
  portfolio_lowercase: 'portefeuille',
  portfolios: 'Portefeuilles',
  company_tag_about: 'sur',
  cryptocurrency_portfolios: 'Portefeuilles de cryptomonnaies',
  create_portfolio: 'Créer un portefeuille',
  create_league_portfolio: 'Rejoindre la ligue',
  create_portfolio_on_welcome: 'Nouveau portefeuille',
  create_portfolio_later: 'Plus tard',
  create_portfolio_league_info_title: 'Informations sur la ligue',
  create_portfolio_league_budget: `Budget du portefeuille`,
  create_portfolio_league_owner: `Propriétaire de la ligue`,
  create_portfolio_league_remaining_time: 'Temps restant',
  portfolio_create_modal_join_league_more_info_title: 'Rejoindre une ligue',
  portfolio_create_modal_join_league_more_info_body: `Créez un portfefeuille pour entrer en compétition! Les liquidités disponibles ainsi que les marchés autorisés sont déterminés
  par le propriétaire de la ligue. Votre portefeuille sera public, et vous gagnerez des points au classement de la ligue et au classement général`,
  new_portfolio: 'Nouveau portefeuille',
  create_profile: 'Créer un profil',
  profile_name_already_taken: "Ce nom d'utilisateur est déjà pris",
  profile_name_validation:
    "Le nom d'utilisateur doit contenir des lettres et chiffres uniquement",
  post: 'Post',
  display_name: "Nom d'affichage",
  name: 'Nom',
  add_new_ticker: 'Ajouter un nouveau ticker',
  first_transition: 'Ajoutez votre première transaction',
  add_tickers: 'Ajouter des tickers',
  add_ticker: 'Ajouter un ticker',
  top_five_trending_indices: 'Indices populaires',
  top_five_trending_cryptos: 'Tendances Cryptomonnaies',
  trending_tickers: 'Tickers populaires',
  trending_losers_tickers: 'Plus fortes baisses',
  trending_gainers_tickers: 'Plus fortes hausses',
  top_five_popular_cryptos: 'Cryptomonnaies populaires',
  widget_recommendation_similar_cryptos_title: 'Cryptomonnaies similaires',
  transaction: 'Transaction',
  location: 'Localisation',
  bio: 'Biographie',
  no_results: 'Aucun résultat',
  create_post: 'Créez un post',
  create: 'Créer',
  notifications_center: 'Centre de notifications',
  notifications: 'Notifications',
  darkmode: 'Mode sombre',
  quantity: 'Quantité',
  keep_under_char: '{max} caractères autorisés. {left} restants.',
  no_favorites: 'Pas encore de favoris.',
  profile_to_use: 'Quel profil voulez-vous utiliser ?',
  currency: 'Devise',
  language: 'Langue',
  preferences: 'Préférences',
  settings_manage_content: 'Gérez votre contenu et vos préférences ici',
  rate: 'Vote',
  save: 'Enregistrer',
  saved: 'Favoris',
  following: 'Suivi',
  follow_btn_followed: 'Suivi',
  followings: 'Suivis',
  followed: 'Suivi',
  followers: 'Abonnés',
  follower: 'Abonné',
  all: 'Tous',
  alert: 'Alerte',
  alerts: 'Alertes',
  no_follower: 'Pas encore de Abonnés',
  follower_count_1: '1 Abonné',
  follower_count: '{count} Abonnés',
  follow: 'Suivre',
  followed_users: 'Utilisateurs suivis',
  followed_companies: 'Sociétés suivies',
  mutual_industries: 'Industries en commun',
  mutual_follows: 'Amis en commun',
  friends: 'amis',
  friend: 'ami',
  industry: 'industrie',
  industries: 'Industries',
  mutual_count: '{count} {object} en commun',
  mutual_count_1: '1 {object} en commun',
  no_mutual: "Pas d'{object} en commun",
  unfollow_company_tooltip:
    'Ne plus suivre une société ne retire pas ses actions de vos portefeuilles',
  your_preference: 'Vos préférences',
  here_favorite_industries: 'Vos industries préférées',
  here_favorite_industries_desc:
    'Vos actualités dépendent des industries que vous suivez',
  my_settings: 'Mes réglages',
  load_more: 'Charger plus…',
  mark_all_as_read: 'Marquer tous comme lus',
  started_follow: 'a commencé à vous suivre',
  start_post: 'Créez un post',
  start_repost: 'Créez un repost',
  report_post: 'Signaler',
  report_length_limit: 'Le signalement ne doit pas excéder 400 caractères',
  report_required: 'Texte requis.',
  send_report_title: 'Envoyer un signalement',
  edit_profile: 'Modifier mon profil',
  compare_to: 'Comparer à',
  compare: 'Comparer',
  add_another: 'Ajouter',
  compare_to_tooltip: 'Vous avez atteint la limite de 5 symboles',
  compare_to_portfolios_tooltip:
    'Vous avez atteint la limite de 5 portefeuilles',
  limit_reached: 'Limite atteinte',
  verified_account: 'Compte vérifié',
  description: 'Description',
  custom_portoflio_description:
    'Ceci est une description de mon nouveau portefeuille',
  liked_your_post: 'a aimé votre post :',
  company: 'Société',
  cryptocurrencies: 'Cryptomonnaies',
  indices: 'Indices',
  index: 'Indice',
  tickers: 'Tickers',
  ticker: 'Ticker',
  financial_data: 'Finances',
  send: 'Envoyer',
  shares: "Nbr d'actions",
  mark_as_read: 'Marquer comme lu',
  mark_as_unread: 'Marquer comme non lu',
  remove_notif: 'Supprimer la notification',
  my_new_portfolio: 'Mon nouveau portefeuille',
  notif_LIKE: 'a aimé votre',
  notif_target_LIKE: 'post',
  notif_REPOST: 'a republié votre',
  notif_target_REPOST: 'post',
  notif_FOLLOW: 'a commencé à vous suivre',
  notif_REPLY: 'a répondu à votre',
  notif_target_REPLY: 'post',
  notif_MENTION: 'vous a mentionné dans un',
  notif_target_MENTION: 'post',
  notif_PORTFOLIOLIKE: 'a aimé votre',
  notif_target_PORTFOLIOLIKE: 'portefeuille',
  notif_PORTFOLIOCOMMENT: 'a commenté votre',
  notif_target_PORTFOLIOCOMMENT: 'portefeuille',
  notif_PORTFOLIOFAVORITE: 'a ajouté votre',
  notif_target_PORTFOLIOFAVORITE: 'portefeuille',
  notif_PORTFOLIOREPOST: 'a republié votre',
  notif_target_PORTFOLIOREPOST: 'portefeuille',
  notif_PORTFOLIOFAVORITE_after: 'à ses favoris',
  notif_PRICECHANGE_INCREASED: 'a augmenté de',
  notif_PRICECHANGE_DECREASED: 'a baissé de',
  notif_PRICECHANGE_after: 'à',
  notif_ADDEDTOPORTFOLIO_before: 'a partagé',
  notif_ADDEDTOPORTFOLIO_after: 'avec vous.',
  notif_PORTFOLIOACTION: 'a ajouté une transaction sur le portefeuille',
  logout: 'Se déconnecter',
  support: 'Support',
  search: 'Recherche',
  'search...': 'Recherche...',
  search_placeholder: 'Rechercher des actions ou cryptos',
  search_ticker_placeholder: 'Rechercher des actions à comparer (max 4)',
  search_portfolio_placeholder:
    'Rechercher des portefeuilles à comparer (max 4)',
  clear_all: 'Effacer',
  recent_news: 'Actualités récentes',
  gif_search: 'Recherche de gif',
  search_anything: 'Recherche…',
  write_reply: 'Ecrire une réponse',
  reply_to_post: 'Ecrire une réponse',
  write_post: 'Ecrire un post',
  write_a_comment: 'Ecrire un commentaire',
  write_here: 'Ecrivez ici',
  alert_select_img: 'Sélectionnez une image.',
  browser_incompatible:
    'Désolé, votre navigateur ne prend pas en charge cette fonctionnalité.',
  joined: 'Rejoint',
  not_connected: "Vous n'êtes plus connecté, veuillez recharger la page",
  what_talk_about: 'De quoi voulez-vous discuter ?',
  refresh: 'recharger',
  they_like: 'Ils aiment',
  max_image_to_upload: 'Vous pouvez ajouter 4 images maximum',
  comment_portfolio: 'Ajouter un commentaire',
  reply: 'Répondre',
  repost_a_post: 'Reposter',
  post_created_ok: 'Votre post a bien été publié',
  error_occured_message: "Une erreur s'est produite",
  skade: 'Skade',
  lorem: '',
  ranked: 'Classé',
  learn_more: 'En savoir plus',
  user_empty_feed: "Cet utilisateur n'a pas encore publié de post.",
  company_empty_feed: "Pas encore d'actualité ici.",
  market_title: 'Marchés - Actualités',
  market_filter_sectors: 'Filtrer par secteur :',
  market_filter_clear: 'Supprimer les filtres',
  general_market_news: 'Actualités globales',
  recent_market_news: 'Récents',
  trending_market_news: 'Populaires',
  my_favorite_sectors_news: 'Secteurs favoris',
  crypto_market_news: 'Cryptomonnaies',
  crypto_title: 'Cryptos - Actualités',
  trending_crypto_news: 'Populaires',
  recent_crypto_news: 'Récents',
  invalid_username_or_password: "Combinaison d'identifiants incorrecte",
  post_disabled_mail_validation:
    'Vous devez confirmer votre email avant de publier un post',
  post_disabled_bad_content:
    'Votre post semble avoir un contenu inapproprié ou dangereux',
  post_disabled_length: 'Votre post est trop long',
  create_portfolio_disabled_mail_validation:
    'Vous devez confirmer votre email avant de créer un portefeuille',
  follow_disabled_mail_validation:
    'Vous devez confirmer votre email avant de pouvoir suivre des comptes',
  error_input_min_length: '{fieldName} doit contenir {min} caractères minimum',
  error_input_required: 'Vous devez indiquer un {fieldName}',
  error_date_before: 'La date doit être avant {maxDate}',
  error_date_after: 'La date doit être après le {minDate}',
  amount_required: 'Montant requis',
  date_required: 'La date est requise',
  name_required: 'Nom requis',
  error_password_not_strong_enough:
    'Le mot de passe doit contenir un chiffre et une majuscule.',
  display_name_required: "Vous devez indiquer un nom d'affichage",
  display_name_max_length:
    "Le nom d'affichage doit contenir {max} caractères maximum",
  location_max_length: 'La localisation doit contenir {max} caractères maximum',
  description_max_length:
    'La description doit contenir {max} caractères maximum',
  email_validation_banner:
    "N'oubliez pas de confirmer votre compte. Email non reçu ? Pensez à vérifier vos spam, ou",
  email_validation_banner_link: "renvoyer l'email",
  settings_section_account: 'Compte',
  settings_section_account_info:
    'Voir et gérer les informations de votre compte',
  settings_section_language: 'Langues',
  settings_section_language_info: 'Voir et gérer la langue de votre contenu',
  settings_section_plan: 'Changer de plan',
  settings_section_plan_info: 'Gérer votre plan',
  settings_section_deactivate: 'Désactiver votre compte',
  settings_section_deactivate_info:
    'Comment désactiver ou supprimer votre compte',
  settings_section_content: 'Contenu',
  settings_section_content_info: 'Choisissez le contenu que vous voulez voir',
  settings_section_password: 'Modifiez votre mot de passe',
  settings_section_password_info:
    'Modifiez votre mot de passe ou réinitialisez-le',
  settings_content_sectors_sectors_title: 'Actualités des marchés',
  settings_content_sectors_sectors_body: 'Personnaliser vos secteurs favoris',
  settings_content_sectors_content_title: 'Contenu',
  settings_content_sectors_content_body: '',
  settings_section_password_current: 'Mot de passe actuel',
  settings_section_password_new: 'Nouveau mot de passe',
  settings_section_password_confirmation: 'Confirmez votre mot de passe',
  settings_section_password_success: 'Votre mot de passe a bien été modifié',
  settings_section_password_errors_password_reused:
    "Votre nouveau mot de passe doit être différent de l'actuel",
  settings_section_password_errors_confirmation_not_matching:
    'Veuillez utiliser le même mot de passe',
  settings_section_password_errors_passwordmismatch:
    'Votre mot de passe actuel est incorrect',
  settings_section_password_errors_api_default:
    'Impossible de modifier le mot de passe',
  settings_language_title: 'Langue',
  settings_am_title: 'Compte',
  settings_am_profileNameSuccess: "Votre nom d'utilisateur a bien été modifié",
  settings_display_language: "Langue d'affichage",
  settings_news_language: "Langue du contenu d'actualité",
  settings_news_language_body: 'English, Français…',
  settings_currency: 'Devise',
  settings_delete_account_confirm: 'Procéder à la suppression',
  settings_delete_account_title: 'Supprimer mon compte',
  settings_delete_account_body:
    'Lorsque vous supprimez votre compte skade, vous ne pourrez plus récupérer le contenu ou les informations que vous avez partagées sur skade. Vos portefeuilles seront également supprimés.',
  settings_delete_account_confirm_title: 'Confirmez la suppression',
  settings_delete_account_confirm_body:
    'Lorsque vous supprimez votre compte skade, vous ne pourrez plus récupérer le contenu ou les informations que vous avez partagées sur skade. Vos portefeuilles seront également supprimés.',
  settings_delete_account_success_title: 'Compte supprimé avec succès',
  settings_delete_account_success_body_1:
    'Votre compte ainsi que toutes vos données ont bien été supprimés.',
  settings_delete_account_success_body_2: 'Nous espérons vous revoir bientôt!',
  settings_delete_account_success_body_3: 'Au revoir 👋',
  settings_delete_account_confirm_modal_body: `Vous êtes sur le point de supprimer définitivement votre compte. Si vous êtes prêt à supprimer, cliquez sur Supprimer le compte. Une fois que vous soumettez votre compte pour suppression, vous ne pourrez plus récupérer aucun contenu ou information que vous avez ajouté.`,
  modals_pr_title: 'Mot de passe oublié ?',
  modals_pr_step: 'Etape {step} sur {maxStep}',
  modals_pr_send_code_title:
    'Comment voulez-vous réinitialiser votre mot de passe ?',
  modals_pr_send_code_email: 'Envoyer un code par email à {email}',
  modals_pr_send_code_phone: 'Envoyer un code par sms à {phone}',
  modals_pr_enter_code_title: 'Vérifiez vos emails.',
  modals_pr_enter_code_label: 'Nous vous avons envoyé un lien par email.',
  modals_pr_enter_code_placeholder: 'Entrer code',
  modals_pr_enter_code_submit: 'Confirmer',
  modals_pr_new_password_title: 'Réinitialisez votre mot de passe',
  modals_pr_new_password_label_1: 'Entrez un nouveau mot de passe',
  modals_pr_new_password_placeholder_1: 'Nouveau mot de passe',
  modals_pr_new_password_label_2: 'Confirmez votre nouveau mot de passe',
  modals_pr_new_password_placeholder_2: 'Confirmer le nouveau mot de passe',
  modals_pr_new_password_password_confirmation: 'Confirmer le mot de passe',
  modals_pr_new_password_submit: 'Réinitialiser le mot de passe',
  modals_pr_new_password_confirmation_not_matching:
    "La confirmation n'est pas identique à votre nouveau mot de passe",
  modals_pr_purpose_title: 'Pourquoi avez-vous changé votre mot de passe ?',
  modals_pr_purpose_1: "J'ai oublié mon mot de passe",
  modals_pr_purpose_2: "Quelqu'un d'autre a accédé à mon compte",
  modals_pr_purpose_3: 'Autre raison',
  modals_pr_purpose_submit: 'Envoyer',
  modals_pr_logout_title: 'Vous êtes prêt !',
  modals_pr_logout_info: 'Votre mot de passe a bien été modifié',
  modals_pr_logout_1: 'Se déconnecter des autres appareils',
  modals_pr_logout_2: 'Rester connecté',
  modals_pr_logout_submit: 'Continuez vers Skade',
  modals_pr_success: 'Votre mot de passe a bien été modifié',
  field_username: "Nom d'utilisateur",
  field_email: 'Email',
  field_birth_date: 'Date de naissance',
  field_exchange: 'Echange',
  field_country: 'Pays',
  profile_follows_all: 'Tout',
  profile_recommended_all: 'Tout',
  profile_follows_users: 'Utilisateurs',
  profile_follows_companies: 'Sociétés',
  profile_follows_cryptocurrencies: 'Cryptomonnaies',
  widget_recommendation_similar_companies_title: 'Autres sociétés',
  widget_recommendation_company_followers_title: 'Suivi par',
  widget_recommendation_no_result: 'Aucun résultat',
  english: 'English',
  french: 'Français',
  add_users: 'Ajouter des utilisateurs',
  search_result_for_literal: 'Résultats pour',
  search_result_for: ({ named }) => `Résultats pour "${named('query')}"`,
  no_search_results: 'Aucun résultat',
  trending_headlines: 'Tendances',
  field_privacy: 'Confidentialité',
  field_portfolio_visibility: 'Visibilité',
  field_portfolio_rankings: 'Compétition',
  field_portfolio_type: 'Type de portefeuille',
  field_currency: 'Devise',
  privacy_public: 'Public',
  privacy_public_unranked: 'Public - Non classé',
  privacy_private: 'Privé',
  private: 'Privé',
  portfolio_is_listed: 'Active',
  post_max_length: 'Limite de caractères : {max}',
  name_max_length: 'Limite de caractères : {max}',
  read_full_article: "Lire l'article",
  read_more: 'Voir plus',
  read_less: 'Voir moins',
  month_abbreviation: 'm',
  day_abbreviation: 'j',
  hour_abbreviation: 'h',
  minute_abbreviation: 'm',
  portfolio_error_max_limit_reached:
    "Vous ne pouvez plus créer d'autre portefeuille sur ce profil",
  portfolio_error_select_one_profile_min:
    'Veuillez sélectionner au moins un profil',
  portfolio_error_name_length:
    'Veuillez choisir un nom de moins de 16 caractères',
  portfolio_name_already_used: 'Vous avez déjà un portefeuille à ce nom',
  portfolio_unranked: 'Non classé',
  portfolio_privacy_message:
    'Votre portefeuille est privé par défaut. Vous trouverez les règles et critères pour participer au classement public une fois votre portefeuille créé.',

  changes_saved_success: 'Vos changements ont été sauvegardés avec succès',
  changes_saved_error:
    "Vos changements n'ont pas pu être sauvegardés à cause d'une erreur inattendue",
  portfolio_settings_content_title: 'Modifier le portefeuille',
  portfolio_delete_confirmation: 'Êtes vous sûr? Cette action est permanente',
  delete_portfolio_success: 'Votre portefeuille a bien été supprimé',
  delete_portfolio_failed: 'La suppression n’a pas abouti',
  field_portfolio_name: 'Nom',
  field_portfolio_portfolio: 'Portefeuille',
  field_portfolio_description: 'Description',
  field_portfolio_owner: 'Propriétaire',
  field_max_length: 'Le champ doit contenir {max} caractères maximum',
  portfolio_type: 'Type - {type}',
  portfolio_public: 'Public',
  portfolio_ranked: 'Classé',
  portfolio_private: 'Privé',
  portfolio_privacy_change_modal_description:
    'Changez la façon dont les autres utilisateurs voient votre portefeuille sur skade.',
  portfolio_privacy_change_modal_private: 'Privé',
  portfolio_privacy_change_modal_private_description_1:
    'Seul vous pouvez voir votre portefeuille.',
  portfolio_privacy_change_modal_private_description_2:
    'Les portefeuilles privés ne participent pas aux classements.',
  portfolio_privacy_change_modal_unlisted: 'Public et non-classé',
  portfolio_privacy_change_modal_unlisted_description:
    'Votre portefeuille peut être vu par ceux qui visitent votre profil. Vous pouvez le partager à tout utilisateur. Les valeurs sont affichées exclusivement en %.',
  portfolio_privacy_change_modal_ranked: 'Classé',
  portfolio_privacy_change_modal_ranked_description:
    'Votre portefeuille reçoit un classement et peut être vu par tous sur skade.',
  portfolio_privacy_change_modal_ranked_conditions:
    'Pour participer aux classements, votre portefeuille doit suivre les règles suivantes : ',
  portfolio_privacy_change_modal_ranked_condition_1:
    'Avoir le statut ‘Public’ depuis minimum une semaine',
  portfolio_privacy_change_modal_ranked_condition_2:
    'Avoir au minimum 5 lignes',
  portfolio_privacy_change_modal_ranked_condition_3:
    'Ne pas ajouter d’opération antérieure à 48 heures',
  portfolio_definition_market_value:
    'Désigne la valeur actuelle de tous les titres (actions, obligations, etc.) détenus dans le portefeuille. Elle est déterminée en multipliant le prix de marché actuel de chaque titre par la quantité totale détenue. La valeur marchande fluctue avec les changements des prix des titres détenus dans le portefeuille et reflète la perception actuelle du marché de leur valeur.',
  portfolio_definition_stock_change:
    'Gains et/ou pertes équivalents à la somme de vos placements au cours des dernières 24h (clôture la plus récente - clôture du jour précédent)',
  portfolio_definition_return: `Profit ou perte générés par les investissements sur une période spécifique. Il représente l'augmentation ou la diminution en pourcentage de la valeur du portefeuille, en tenant compte de la variation des prix des actions détenues dans le portefeuille.<br/><br/>Note : Cela n'inclut pas les dividendes (pour l'instant), taxes et frais de transaction éventuels.`,
  portfolio_onboarding_title:
    'Les portefeuilles les plus performants à portée de vue.',
  portfolio_onboarding_part_1:
    'Sélectionnez des portefeuilles pour les ajouter à vos favoris et suivre leurs performances.',
  portfolio_onboarding_part_2:
    '(Tous les portefeuilles publics ne sont affichés qu’en pourcentage. Les montants réels sont réservés exclusivement au propriétaire du portefeuille)',
  stocks: 'Actions',
  assets: 'Actifs',
  stocks_select_followed_stocks: 'Actions suivies',
  stocks_select_stocks: 'Actions',
  cryptos_select_followed_cryptos: 'Cryptomonnaies suivies',
  cryptos_select_cryptos: 'Cryptomonnaies',
  stocks_table_header_stock: 'Action',
  stocks_table_header_company: 'Société',
  stocks_table_header_cryptocurrency: 'Cryptomonnaie',
  stocks_table_header_cryptocurrency_short: 'Crypto',
  stocks_table_header_exchange: 'Echange',
  stocks_table_header_followers_count: 'Abonnés',
  stocks_table_header_price: 'Cours',
  stocks_table_header_change_percent: '± value %',
  stocks_table_header_follow_status: 'Suivi',
  stocks_table_header_follow_stock_change:
    'Recevoir une alerte si le prix de l’action change de plus de 1%',
  stocks_table_header_stocks_change: 'Alertes',
  stocks_table_header_options: 'Choix',
  stocks_table_header_alerts:
    'Vous serez notifié quand il y aura un changement de plus de 1% du cours de l’action',
  stocks_table_header_add_to_portfolio: 'Opération',
  stocks_table_header_add_to_portfolio_mobile: 'Ajout',
  stocks_table_header_add_to_portfolio_tooltip: 'Ajouter au portfolio',
  stocks_table_show_more: 'Voir plus',
  Crypto: 'Cryptocurrency',
  widget_portfolio_global_benefit: 'Bénéfices',
  terms_of_use: 'Conditions d’utilisation',
  privacy_policy: 'Politique de confidentialité',
  about_us: 'À propos de nous',
  messaging_privacy: 'Messages Privés',
  recommended_profiles: 'Profils recommandés',
  settings_section_cookies: 'Cookies',
  settings_section_cookies_info: 'Préférences en matière de cookies',
  settings_general_account: 'Général',
  settings_security_and_privacy: 'Confidentialité',
  settings_privacy_and_terms_info: 'Consultez nos politiques et conditions',
  settings_privacy_and_terms: 'Confidentialité et conditions',
  settings_privacy_policy: 'Politique de confidentialité',
  settings_privacy_messaging_title: 'Qui peut m’envoyer un message?',
  settings_privacy_messaging_subtitle:
    'Les utilisateurs que vous suivez déjà pourront toujours vous envoyer des messages.',
  settings_privacy_messaging_option_1: 'Tout le monde',
  settings_privacy_messaging_option_2: 'Seulement les comptes que je suis',
  settings_terms_of_use: 'Conditions d’utilisation',
  settings_support_info: 'Contactez-nous pour toute demande ou commentaire',
  private_resource_title: 'Rien à voir ici',
  private_resource_description: 'Cette page est privée ou n’existe pas',
  portfolio_table_price: 'Actuel',
  portfolio_operation_table_price: 'Valeur',
  portfolio_operation_table_shares: 'Actions',
  portfolio_operation_table_total_price: 'Total',
  portfolio_operation_table_buy_price: "Prix d'achat",
  portfolio_operation_table_current_price: 'Cours actuel',
  portfolio_operation_table_return: '± value %',
  portfolio_operation_table_initiated_by: 'Utilisateur',
  portfolio_operation_table_stock: 'Actifs',
  portfolio_operation_table_transaction: 'Transaction',
  ticker_table_price: 'Prix actuel',
  ticker_table_asset: 'Actifs',
  ticker_table_cost: "Prix d'achat ",
  ticker_table_cost_short: 'Prix unit. ',
  ticker_table_cost_tooltip: `Le prix d'achat moyen est une mesure qui représente le coût moyen d'acquisition d'un actif détenu dans le portefeuille.
  Il est calculé en additionnant le coût total de tous les achats de cet actif et en divisant par le nombre total d'unités de cet actif achetées.`,
  ticker_table_return: '± value',
  ticker_table_total_value: 'Valeur totale',
  ticker_table_total_value_short: 'Valeur tot.',
  portfolio_table_price_tooltip: "Cours actuel de l'action",
  portfolio_table_change: 'Variation',
  portfolio_table_market_value: 'Valeur totale',
  portfolio_table_cost: "Valeur d'achat",
  portfolio_table_cost_tooltip: 'Cours à la date de transaction',
  portfolio_table_gain: '± value',
  portfolio_table_return: '± value %',
  portfolio_card_today: "Aujourd'hui",
  portfolio_card_overall: 'Total',
  portfolio_top_gainer_title: 'Top Portefeuilles',
  portfolio_profile_title: 'Portefeuilles de {profileName}',
  portfolio_comments_title: 'Conversation',
  portfolio_change_percent_tooltip: 'Le {date}, update quotidienne',
  cash: 'Liquidités',
  cash_available: 'Liquidités disponibles le {date}',
  search_label: 'Acheter',
  sell_label: 'Vendre',
  buy_with: 'Acheter avec',
  quantity_placeholder: 'e.g. 10',
  not_enough_cash_at_date: 'Pas assez de liquidités disponibles à cette date',
  before_date: 'La date la plus récente autorisée est le le {date}',
  cash_will_be_added: '{amount} sera ajouté au portfolio',
  portfolio_ranking_tooltip_unranked:
    'Pour entrer en compétition avec les autres utilisateurs, vous devez rendre votre portefeuille public',
  portfolio_ranking_tooltip_unlisted:
    'Votre portefeuille est public, mais pas encore éligible pour entrer en compétition ({timer} restant)',
  portfolio_ranking_tooltip_can_get_listed:
    'Votre portefeuille est public et éligible pour entrer en compétition',
  portfolio_make_public_confirmation:
    'Quand votre portefeuille sera public, vous ne pourrez plus ajouter de transaction antérieure à 5 jours. Il sera inclu dans les classements dans 1 mois. Voulez-vous continuer?',
  portfolio_make_private_confirmation:
    'Votre portefeuille sera exclu des classements. Voulez-vous continuer ?',
  unlisted_portfolio_date_warning: `Toute transaction antérieure à 2 jours retardera l'entrée en compétition`,
  rankings: 'Classement',
  team_portfolio_share_table_access: 'Accès',
  team_portfolio_share_table_users_access: 'Utilisateurs ayant accès',
  team_portfolio_modal_search: 'Rechercher des utilisateurs',
  team_portfolio_modal_add_user: 'Ajouter l`utilisateur',
  team_portfolio_dropdown_edit: 'Éditeur',
  team_portfolio_dropdown_view_only: 'Lecture seule',
  team_portfolio_dropdown_revoke_access: 'Retirer l’accès',
  team_portfolio_modal_share_portfolio_access: 'Partager ',
  team_portfolio_user_role_owner: 'Propriétaire',
  team_portfolio_user_role_editor: 'Éditeur',
  team_portfolio_user_role_viewer: 'Lecture seule',
  portfolio_manage_users: 'Gestion des droits',

  portfolios_all: 'Tous les portefeuilles',
  portfolios_favorite: 'Mes portefeuilles favoris',
  users_i_follow: 'Utilisateurs suivis',
  portfolio_complete_view: 'Vue',
  portfolio_public_view: 'Vue publique',

  ticker_drawer_current_price: 'Prix actuel',
  ticker_drawer_24H: '24H',
  ticker_drawer_isin: 'ISIN',
  ticker_drawer_pe_ratio: 'PE ratio',
  ticker_drawer_52_weeks: '52 semaines',
  ticker_drawer_quantity: 'Quantité',
  ticker_drawer_average_price: 'Prix d’achat moyen',
  ticker_drawer_total: 'Total',
  ticker_drawer_return: '± value',
  ticker_drawer_buy: 'Acheter',
  ticker_drawer_sell: 'Vendre',
  order_drawer_sort: 'Ordre',
  order_drawer_by: 'Par',
  order_drawer_total_value: 'Valeur totale',
  order_drawer_gain: '± Value',
  order_drawer_name: 'Nom (A-Z)',
  order_drawer_order: 'Sens',
  order_drawer_ascending: 'Ascendant',
  order_drawer_descending: 'Descendant',
  order_drawer_followers: 'Abonnés',
  order_drawer_price: 'Cours',
  order_drawer_perf: 'Perf 24h',
  interval_filter_drawer_period: 'Période',

  //==============================================
  // About us
  //==============================================

  about_us_why: 'Pourquoi ?',
  about_us_why_1:
    'N’avons-nous pas tous rêvé d’un monde où l’entraide et le partage sont des valeurs fondamentales ?',
  about_us_why_2:
    'Chez Skade, nous sommes animés par cette idée qu’ensemble nous sommes plus forts, plus intelligents et plus résilients face aux situations qui peuvent nous sembler compliquées, comme l’investissement en bourse.',
  about_us_why_3:
    'Nous savons aussi à quel point il peut être difficile de parler d’argent et de placements financiers avec sa famille ou ses amis.',
  about_us_why_4:
    'Pourtant, il serait bénéfique d’en parler pour apprendre, partager les raisonnements et les expériences, afin de décider plus intelligemment en échangeant avec d’autres particuliers confrontés aux mêmes questions.',
  about_us_why_5:
    "Chacun de nous a le souhait de faire fructifier ses économies pour le futur en les protégeant de l’inflation. Nous savons aussi que l'un des moyens les plus efficaces de les préserver à long terme est de les investir judicieusement en bourse en limitant les frais tout en répartissant les risques.",
  about_us_why_6:
    "C’est pour cette raison que nous avons créé Skade en 2021 au cœur de la pandémie, durant laquelle il était si difficile d'échanger et paradoxalement si propice à l’investissement.",

  about_us_vision: 'Notre vision',
  about_us_vision_1:
    'Investir nos économies en prenant les bonnes décisions pour les faire fructifier sans prendre de risques déraisonnables n’est pas chose évidente. Beaucoup y pensent mais n’osent pas franchir le pas, que ce soit par peur ou par manque de connaissance. La réalité est que nous sommes bien seuls quand il s’agit de prendre des décisions si importantes.',
  about_us_vision_2:
    'Skade a pour but de rassembler les investisseurs particuliers européens au sein d’une plateforme communautaire qui rend l’univers de la bourse et des cryptomonnaies plus accessible à tous.',
  about_us_vision_3:
    'Nous créons un réseau social où les plus avisés peuvent conseiller les novices et où chacun pourra donner son avis, partager ses expériences et ses performances. C’est ensemble que nos savoirs comptent et le monde de la bourse ne fait pas exception.',
  about_us_vision_4:
    'Nous ne souhaitons en aucun cas prendre le rôle des banques, brokers ou conseillers financiers. L’objectif de Skade est au contraire d’en rester totalement indépendant et complémentaire pour servir au mieux notre communauté.',
  about_us_vision_5:
    'C’est pour cela que nous voulons offrir à chacun des outils simples et efficaces pouvant les accompagner dans leurs démarches d’investissement en bourse et cela quels que soient leurs connaissances initiales, leurs moyens et objectifs',

  about_us_intro_1:
    'Skade est une nouvelle plateforme sociale pour les investisseurs individuels, qui couvre le marché de la bourse et les cryptomonnaies.',
  about_us_intro_2:
    'Les utilisateurs peuvent suivre leurs investissements grâce aux fils d’actualité financière ainsi que leur outil de portefeuille virtuel.',
  about_us_vision_title: 'Notre vision',
  about_us_vision_text:
    'Rassembler les investisseurs et rendre le monde de la bourse et des cryptomonnaies plus accessible à tous.',
  about_us_mission_title: 'Notre mission',
  about_us_mission_text:
    'Devenir un outil complémentaire aux intermédiaires financiers comme les banques afin d’apporter aux utilisateurs la meilleure expérience de l’investissement.',
  about_us_who_we_are_title: 'Qui sommes-nous ?',
  about_us_who_we_are_text_1:
    'L’idée de Skade est venue pour deux chefs de projet digital, Louis et Manon, intéressés par l’investissement, mais bloqués par la complexité de la bourse.',
  about_us_who_we_are_text_2:
    'Après avoir étudié les outils pour investisseurs existants en ligne, aucune plateforme ne semblait satisfaire tous les besoins de personnes souhaitant débuter dans le monde de l’investissement.',
  add_more_transaction: "Ajouter d'autres transactions",
  new_transaction: 'Transaction',
  operation_sell_without_shares: 'Aucune action à vendre à cette date',
  add_transaction: 'Ajouter la transaction',
  save_and_add_another: "Ajouter d'autres transactions",
  welcome_portfolios: 'Bienvenue dans vos portefeuilles !',
  welcome_portfolios_catchline:
    'Créez votre premier portefeuille et suivez une simulation de vos investissements sur les marchés.',
  simulate_title: 'Simulation',
  simulate_text: 'Créez et gérez vos portefeuille digitaux',
  up_to_date_title: 'A jour',
  up_to_date_text: 'Données mises à jour quotidiennement',
  stocks_crypto_title: 'Bourse et Crypto',
  stocks_crypto_text: 'Suivez vos portefeuilles en bourse et crypto',
  compete_with_best_title: 'Rivalisez avec les meilleurs',
  compete_with_best_text:
    'Apprenez avec les Top Portefeuilles de la communauté',
  follow_favorites_title: 'Suivez vos favoris',
  follow_favorites_text: 'Gardez un œil sur les portefeuilles que vous aimez',

  portfolio_empty_title: 'Ce portefeuille est vide !',
  portfolio_empty_text: 'Ajoutez vos premières transactions pour commencer !',
  public_portfolio_empty_title: 'Ce portefeuille est vide !',
  public_portfolio_empty_text: 'Revenir en arrière',
  available_cash: 'Liquidités disponibles : {amount}',
  portfolio_suggestions_title: 'Quelques suggestions :',
  portfolio_add_favorite: 'Ajouter ce portefeuille aux favoris',
  portfolio_like_tooltip: 'Recommander ce portefeuille',
  portfolio_conversation_tooltip: 'Commenter sur ce portefeuille',
  portfolio_remove_favorite: 'Retirez ce portefeuille des favoris.',
  show_more_replies: 'Afficher {count} réponses supplémentaires',
  show_more_replies_1: 'Afficher 1 réponse supplémentaire',
  show_more_replies_without_count: 'Afficher réponses supplémentaires',
  show_replies: 'Afficher {count} réponses',
  show_replies_1: 'Afficher 1 réponse',
  portfolio_main_currency: 'Devise principale',
  portfolio_cash: 'Liquidités',
  portfolio_cash_help: 'taux du {date}',
  add_more_currencies: "Ajouter d'autres liquidités",
  field_operation_quantity_min: `Entrez un nombre d’actions pour réaliser la transaction`,
  field_public_portfolio_operation_date_after:
    'Votre portefeuille est public, vous ne pouvez pas ajouter de transactions antérieures à 2 jours ouvrés',
  field_portfolio_operation_before_old_one:
    "Vous ne pouvez pas ajouter d'opération antérieures à vos autres opérations sur ce stock. Date minimale : {minDate}",

  portfolio_create_modal_creation_date_title:
    'Information importante concernant la date de création',
  portfolio_create_modal_creation_date_body_question:
    'Pourquoi la date de création?',
  portfolio_create_modal_creation_date_body_answer:
    'Les liquidités seront ajoutées à la Date de Création du portefeuille. Toute transaction antérieure changera vos stats/graphiques. Choisissez cette date judicieusement pour suivre les règles de la vie réelle.',
  portfolio_create_modal_privacy_private_body: 'Seulement visible par vous',
  portfolio_create_modal_privacy_public_body:
    'Visible par tous, en pourcentages uniquement',
  portfolio_create_modal_privacy_help:
    'Ce paramètre peut être modifié plus tard dans vos réglages',

  // ==============================================
  // Home page
  // ==============================================
  home_page_title: 'Smarter together.',
  home_page_subtitle:
    "Apprenez avec votre communauté d'investisseurs individuels.",

  home_page_community_title_1: 'Apprenez',
  home_page_community_title_2: 'avec votre',
  home_page_community_title_3: 'communauté',
  home_page_community_title_4: 'd’investisseurs individuels.',

  home_page_community_text_1_1: '',
  home_page_community_text_1_2: 'Créez votre',
  home_page_community_text_1_3: 'réseau',

  home_page_community_text_2: 'Gérez votre profil et vos échanges',

  home_page_community_text_3_1: 'Participez aux',
  home_page_community_text_3_2: 'conversations',
  home_page_community_text_3_3: '',

  home_page_portfolio_title_1: 'Apprenez à investir',
  home_page_portfolio_title_2: 'en rivalisant avec vos amis !',

  home_page_portfolio_text_1: 'Gérez vos portefeuilles virtuels',
  home_page_portfolio_text_2_1: 'Comparez-vous aux',
  home_page_portfolio_text_2_2: 'Top Community Portfolios',
  home_page_portfolio_text_3_1: 'Surpassez les meilleurs pour',
  home_page_portfolio_text_3_2: 'gagner des récompenses !',

  home_page_content_title_1: 'Ce qui',
  home_page_content_title_2: 'compte pour vous',
  home_page_content_title_3: '.',

  home_page_content_text_1_1: 'Suivez les actualités',
  home_page_content_text_1_2: 'des sociétés',
  home_page_content_text_1_3: '',

  home_page_content_text_2_1: 'Des actualités propres à',
  home_page_content_text_2_2: 'vos intérêts',
  home_page_content_text_2_3: '',

  home_page_content_text_3_1: 'Ne manquez pas',
  home_page_content_text_3_2: 'les tendances',
  home_page_content_text_3_3: '',

  last_updated: 'mis à jour',

  home_page_mobile_title_1: 'Accessible sur PC et mobile',

  your_social_financial_platform: 'Votre réseau social financier',

  legal_notice: 'Mentions légales',

  all_rights_reserved: 'Tous droits réservés.',
  start_taking_control_its_free: 'Prenez le contrôle, c’est gratuit !',
  community: 'Communauté',
  show_me: 'Voir plus',
  ok_what_else: "Quoi d'autre ?",
  cool_and_then: 'Cool, et après ?',
  content: 'Contenu',
  virtual_portfolio: 'Portefeuille virtuel',

  legal_welcome: 'Bienvenue sur skade',
  legal_title: 'On y est presque !',
  reminder: 'Rappel important',
  understood: "J'ai compris",

  legal_disclaimer_p1s1:
    "Les activités de placement, d'investissement en bourse et de trading présentent des risques, notamment en perte de capital.",
  legal_disclaimer_p1s2:
    'Elles doivent donc être faites avec prudence et réflexion personnelle, en fonction des moyens, horizon d’investissement et objectifs de chaque personne.',

  legal_disclaimer_p2s1:
    'Tous les avis et opinions évoqués dans ce site ne sont faits',
  legal_disclaimer_p2_bold: "qu'à titre d'information",
  legal_disclaimer_p2s2:
    "pour aider les membres à réfléchir et ne reflète que l’avis personnel de celui qui l'émet.",

  legal_disclaimer_p3s1:
    'Les informations et avis personnels des émetteurs sont communiqués à titre personnel, par des',
  legal_disclaimer_p3_bold: 'non-professionnels',
  legal_disclaimer_p3s2:
    'de la bourse ou de la finance qui doivent les communiquer de bonne foi, en toute indépendance et sans intérêts cachés.',

  //==============================================
  // Messages Page
  //==============================================
  messages_title: 'Messages',
  type_message_prompt: 'Ecrire un message',
  message_search: 'Rechercher',
  new_message: 'Nouveau message',
  new_message_to: 'A:',
  replying_to_sender_me: 'Moi',
  replying_to: 'Répondre à',
  sent_gif: 'A envoyé un GIF',
  sent_image: 'A envoyé une image',
  view_profile: 'Voir profil',
  delete_conversation: 'Supprimer la conversation',
  report_from_conversation: 'Signaler',
  report_contact_header: 'Signaler {contact}',
  report_contact_checkbox_label: 'Signaler ce contact',
  report_contact_body_1: 'Ce message sera envoyé à Skade.',
  report_contact_body_2: 'Ce contact ne sera pas notifié.',
  block: 'Bloquer',
  unblock: 'Débloquer',
  blocked_contact_message:
    'Ne peut envoyer de message à un compte bloqué {contact}',
  block_contact_header: 'Bloquer {contact}',
  block_contact_checkbox_label: 'Bloquer ce contact',
  block_contact_body_1:
    'Les derniers messages de cet utilisateur seront transmis à skade.',
  block_contact_body_2:
    'Les contacts bloqués ne pourront plus vous envoyer de message. Ce contact ne sera pas informé.',
  unblock_contact_header: 'Débloquer {contact}',
  copy: 'Copier',
  reaction_preview_text: '{displayName} a réagi {reaction} à {message}',
  reaction_preview_text_first_person: 'J’ai réagi {reaction} à {message}',
  reaction_preview_gif: 'GIF',
  reaction_preview_image: 'image',
  // ==============================================
  // HELP CENTER
  // ==============================================

  help_center: 'Centre d’aide',
  help_center_button: 'Aide',
  help_center_button_2: 'Visite guidée',
  help_center_greeting_1: 'Bonjour !',
  help_center_greeting_2: 'Je suis Max, votre guide.',
  getting_started: 'Commencer',

  help_general_walkthrough: 'Visite guidée',
  help_general_walkthrough_content:
    "Voici votre page d'accueil personnalisée. Vous y retrouverez votre menu, le contenu que vous suivez, et les tendances actuelles.",
  help_portfolio: 'Créer et gérer ses portefeuilles',
  help_portfolio_content:
    'Depuis le menu Portefeuilles, vous pouvez créer et gérer vos propres portefeuilles virtuels. Vous verrez aussi les Top Portefeuilles et pourrez ajouter tout portefeuille public à vos favoris.',
  help_feed: 'Personnaliser votre contenu',
  help_feed_content:
    "En utilisant notre barre de recherche, vous pouvez trouver des sociétés et utilisateurs à suivre. Votre page d'accueil inclura les posts des utilisateurs que vous suivez ainsi que les actualités des sociétés suivies.",
  help_settings: 'Modifier les réglages',
  help_settings_content: '',
  help_settings_modal: 'Vos réglages sont ici !',

  other_links: 'Autres liens utiles',
  help_support_portal: 'Portail de support',
  help_support_portal_description: 'Articles, questions...',
  help_contact: 'Contactez-nous',
  help_contact_description: '',
  help_contact_unwrap:
    "Contactez-nous à l'adresse {link} et nous reviendrons vers vous dans les meilleurs délais.",

  help_waltkthrough_p1_title: "Voici votre fil d'actualités",
  help_waltkthrough_p1_subtitle: 'Que comprend-t-il ?',
  help_waltkthrough_p1_l1: 'Les actualités des sociétés que vous suivez',
  help_waltkthrough_p1_l2: 'Les posts des utilisateurs que vous suivez',
  help_waltkthrough_p1_l3: 'Vos propres posts',

  help_waltkthrough_p2_title: 'Créer un post ici',
  help_waltkthrough_p2_subtitle1: 'Qui peut voir mes posts et où?',
  help_waltkthrough_p2_text1:
    'Vos posts sont publics. Ils sont visibles par les utilisateurs qui vous suivent ou qui visitent votre profil.',
  help_waltkthrough_p2_text2:
    "Vos posts apparaissent sur votre profil dans votre page d'accueil, et dans les fils des utilisateurs qui vous suivent.",
  help_waltkthrough_p2_subtitle2: 'Autre petit conseil ?',
  help_waltkthrough_p2_text3:
    'Vous pouvez mentionner un autre utilisateur dans un post en tapant "@" suivi de son nom d\'utilisateur (exemple "@Max")',
  or_here: 'Ou ici',

  help_feed_title: 'Suivre des sociétés et utilisateurs',
  help_feed_subtitle: 'Comment trouver des sociétés et utilisateurs à suivre ?',
  help_feed_text:
    'Utilisez la barre de recherche en tapant le nom des sociétés ou utilisateurs que vous cherchez.',

  include_cash: 'Inclure Liquidités',
  include_cash_shortened: 'Liquidités',
  include_cash_disabled_with_cash: 'Liquidités inclues',
  include_cash_disabled_without_cash: 'Liquidités non inclues',

  //==============================================
  // Stocks Fundamentals
  //==============================================
  fundamentals_volume: 'Volume',
  fundamentals_volume_definition:
    "Le nombre total d'actions ou de contrats échangés",
  fundamentals_revenue: 'Revenus',
  fundamentals_revenue_definition: 'Le revenu total généré par une entreprise',
  fundamentals_market_capitalization: 'Capitalisation',
  fundamentals_market_capitalization_definition:
    "La valeur totale des actions en circulation d'une entreprise",
  fundamentals_dividends: 'Dividendes',
  fundamentals_dividends_definition:
    'Paiements effectués par une société à ses actionnaires',
  fundamentals_shares_outstanding: 'Shares Outstanding',
  fundamentals_shares_outstanding_definition:
    "Le nombre total d'actions émises par une société et détenues par les investisseurs",
  fundamentals_52_weeks_range: '52 semaines',
  fundamentals_52_weeks_range_definition:
    "Les prix les plus élevés et les plus bas auxquels une action a été négociée au cours de l'année écoulée",
  fundamentals_beta: 'Beta',
  fundamentals_beta_definition:
    "Une mesure de la volatilité d'une action par rapport au marché",
  fundamentals_pe_ratio: 'PE Ratio',
  fundamentals_pe_ratio_definition:
    "Le ratio cours/bénéfice, calculé en divisant le cours actuel de l'action par le bénéfice par action",
  fundamentals_eps: 'EPS',
  fundamentals_eps_definition:
    "Bénéfice par action, calculé en divisant le bénéfice net par le nombre total d'actions en circulation",
  fundamentals_isin: 'ISIN',
  fundamentals_isin_definition:
    "SIN (International Securities Identification Number) est un code d'identification unique attribué à chaque titre financier négociable. Il est utilisé pour identifier de manière unique des actions, des obligations, des fonds communs de placement et d'autres instruments financiers sur les marchés internationaux",
  fundamentals_help_card_title: 'Que veulent dire ces données',
  fundamentals_help_cta: 'En savoir plus',
  fundamentals_crypto_market_cap: 'Capitalisation',
  fundamentals_crypto_market_cap_definition:
    "La valeur totale des réserves en circulation d'une cryptomonnaie. Elle est analogue à la capitalisation free-float du marché boursier.",
  fundamentals_crypto_diluted_market_cap: 'Capitalisation diluée',
  fundamentals_crypto_diluted_market_cap_definition:
    "La capitalisation boursière si l'offre maximale était en circulation.",
  fundamentals_crypto_circulating_supply: 'Monnaie en circulation',
  fundamentals_crypto_circulating_supply_definition:
    'Le montant des monnaies en circulation sur le marché et entre les mains du public. Ce montant est analogue à la fluidité des actions sur le marché boursier.',
  fundamentals_crypto_total_supply: 'Offre totale',
  fundamentals_crypto_total_supply_definition:
    'Le montant de cryptomonnaies déjà créées, moins toute monnaie détruite. Ce montant est analogue aux actions en circulation du marché boursier.',
  fundamentals_crypto_max_supply: 'Offre maximale',
  fundamentals_crypto_max_supply_definition:
    'Le montant maximal de monnaies qui puisse exister pendant la durée de vie de la cryptomonnaie. Il est analogue aux actions diluées du marché boursier.',
  fundamentals_crypto_market_cap_dominance: '% Capitalisation',
  fundamentals_crypto_market_cap_dominance_definition:
    'La part de cette monnaie dans la capitalisation globale des cryptomonnaies',
  fundamentals_crypto_all_time_low: 'Plus bas',
  fundamentals_crypto_all_time_low_definition:
    'Valeur la plus basse jamais atteinte',
  fundamentals_crypto_all_time_high: 'Plus haut',
  fundamentals_crypto_all_time_high_definition:
    'Valeur la plus haute jamais atteinte',
  company_fundamentals_title: 'Données financières',
  fundamentals_open_figi: 'OpenFigi',
  fundamentals_code: 'Code',
  fundamentals_returns_1Y: 'Rendement 1A',
  fundamentals_country: 'Pays',
  fundamentals_total_assets: 'Totalité des actifs',
  fundamentals_volatility_1Y: 'Volatilité 1A',
  fundamentals_morning_star_ratio: 'Ratio MorningStar',
  fundamentals_sustainability_ratio: 'Ratio Durabilité',
  fundamentals_average_market_cap: 'Cap boursière moyenne',

  // ==============================================
  // Errors
  // ==============================================
  error_avatar_upload: 'Erreur lors de l’envoi de la photo de profil',
  error_header_upload: 'Erreur lors de l’envoi de la photo de bannière',
  uncomplete_stock_data: `Les dernières données de notre fournisseur pour ce symbole datent du {date}.
    Nous faisons tout notre possible pour obtenir une mise à jour dans les meilleurs délais.`,
  no_stock_data:
    'Aucune donnée disponible pour ce symbole à ce jour (erreur de notre fournisseur)',
  unknown_data: 'N/C',

  /// NEWS PORTFOLIO MODAL - TO REVERT
  news_update_modal_title: 'Bienvenue sur les nouveaux Portefeuilles !',
  news_update_modal_body:
    'Avec cette nouvelle mise-à-jour, nous avons ajouté les liquidités aux portefeuilles. De ce fait, les portefeuilles existants ont du être vidés.',
  news_update_modal_cta: 'Découvrez les nouveaux portefeuilles ici !',

  //==============================================
  // Feed welcome card
  //==============================================
  feed_welcome_card_title: 'Bienvenue sur skade !',
  feed_welcome_card_body: `Ton premier portefeuille n’attend plus que toi. Et si tu te sens prêt à rivaliser avec les meilleurs skaders, l’entrée en compétition est gratuite !`,

  go_to_profile: 'Profil de {name}',

  //==============================================
  // Leagues
  //==============================================
  leagues: 'Ligues',
  league: 'Ligue',
  create_league: 'Créer une ligue',
  league_help_card_title: 'Bienvenue dans les ligues! 🏆',
  league_help_card_catchline:
    'Join a league, or create your own private league. Gain points, and become the best investor!',
  create_league_modal_name: 'Nom de la ligue',
  create_league_modal_name_required: 'Le nom est requis',
  create_league_modal_name_max_length: 'Le nom est trop long (max: {max})',
  create_league_modal_description: 'Description',
  create_league_modal_description_placeholder:
    'Entrez une déscription pour cette ligue',
  create_league_modal_description_max_length:
    'La description est trop longue (max: {max})',
  create_league_modal_start_date: 'Début',
  create_league_modal_end_date: 'Fin',
  create_league_modal_end_main_currency: 'Devise principale',
  create_league_modal_currencies: 'Monnaies',
  create_league_modal_amount: 'Montant',
  create_league_modal_exchanges: 'Échanges',
  create_league_modal_exchanges_format:
    'Le format doit être de type XNAS;XNYS;XPAR...',
  create_league_modal_league_type: 'Type de ligue',
  create_league_modal_max_player_count: 'Nombre max de joueurs',
  create_league_modal_is_ranked: 'Ligue classée',
  create_league_modal_privacy_public:
    'Crée une ligue public que tout le monde peut rejoindre',
  create_league_modal_privacy_private:
    "Invitez d'autres joueurs dans une ligue privée",
  my_leagues: 'Mes ligues',
  public_leagues: 'Ligues publiques',
  leaderboard: 'Classement',
  leagues_filter_all: 'Top',
  leagues_filter_archived: 'Archivées',
  leagues_filter_joined: 'Mes ligues',
  leagues_filter_ongoing: 'En cours',
  leagues_filter_ranked: 'Classées',
  leagues_filter_upcoming: 'Prochainement',
  leaderboard_me_title: 'Mes Portefeuilles',
  leaderboard_others_title: 'Joueurs',
  leaderboard_filter_worldwide: 'Monde',
  leaderboard_filter_followed: 'Suivis',
  league_created_by: 'Par {name}',
  league_ends: 'Termine',
  league_stats_legend_cash: 'Budget',
  league_stats_legend_exchanges: 'Échanges',
  league_stats_legend_prize: 'Récompense',
  join_league: 'Rejoindre',
  all_leagues: 'Toutes les ligues',
  league_table_name: 'Nom',
  league_table_player_count: 'Joueurs',
  league_table_cash_amount: 'Budget',
  league_table_exchanges: 'Échanges',
  league_table_remaining_time: 'Temps',
  league_table_remaining_time_finished: 'Terminée',
  league_table_rank: 'Mon rang',
  league_creation_success: 'Ligue créée avec succès',
  league_creation_error: 'Une erreur est survenue, veuillez réessayer',
  league_notif_before: 'Vous êtes invité à rejoindre une ',
  league_pending_invitations: 'Invitations',
  league_invitation_accept: 'Accepter',
  league_invitation_reject: 'Refuser',
  league_leave: 'Quitter la ligue',
  league_all_exchanges_allowed: 'Tout échange',
  //==============================================
  // Polling
  //==============================================
  poll_total_votes: 'Votes',
  poll_vote_button_text: 'Voter',
  poll_message: 'Merci !',
  poll_remove_poll: 'Effacer le sondage',
  poll_add_option: 'Ajouter une option',
  poll_option: 'Option',
  poll_question_hint: 'Ecrivez une question',

  premium_only: 'Premium uniquement',
  deleted_user: 'Profil supprimé',
  deleted_post_body: 'Post supprimé',
  deleted_comment_body: 'Commentaire supprimé',
  create_post_character_left: '({charLeft} restant)',

  //==============================================
  // Toast messages
  //==============================================
  validation_email_success_toast: 'Email de confirmation envoyé!',
  added_to_favorites_toast: 'Bien ajouté à vos favoris',
  removed_from_favorites_toast: 'Bien retiré de vos favoris',
  report_success_toast: 'Signalement bien envoyé',
  cant_report_again_toast: 'Vous ne pouvez plus signaler ce post',
  error_favorite_page: 'Erreur sur les favoris',
  error_home_page: 'Erreur sur la page d’accueil',
  error_follow_industry: 'Erreur de suivi',
  error_market_page: 'Erreur sur la page des marchés',
  error_loading_industries: 'Erreur de chargement',
  error_loading_messages: 'Erreur de chargement',
  error_post_page: 'Erreur de post',
  error_search: 'Erreur de recherche',
  error_user_search: 'Erreur de recherche',
  error_company_search: 'Erreur de recherche',
  error_crypto_search: 'Erreur de recherche',
  error_ticker_search: 'Erreur de recherche',
  error_hashtag_search: 'Erreur de recherche',
  error_indices_search: 'Erreur de recherche',
  error_loading_notifications: 'Erreur de chargement',
  error_loading_more_notifications: 'Erreur de chargement',
  error_loading_profile: 'Erreur de chargement',
  error_loading_profile_card: 'Erreur de chargement',
  error_loading_profile_feed: 'Erreur de chargement',
  error_loading_symbol_profile: 'Erreur de chargement',
  error_post_page_replies: 'Erreur de post',
  error_post_page_post: 'Erreur de post',
  error_follow: 'Erreur sur le suivi',

  // ==============================================
  // Time intervals
  // ==============================================
  twenty_four_hours: '24H',
  one_week: '1 s',
  seven_days: '7J',
  one_month: '1 m',
  three_month: '3 m',
  six_month: '6M',
  one_year: '1 a',
  five_year: '5 a',
  all_time: 'Tout',

  posession: ({ named }) => `${named('obj')} de ${named('subj')}`,
  profile_picture: 'Photo de profil',
  enquote: ({ named }) => `«${named('content')}»`,
  preposition_in: ({ named }) => `${named('subj')} dans ${named('obj')}`,
  the_last_24h: 'les dernières 24 heures',
  menu: 'Menu',
  logo: 'Logo',
  search_icon: 'icône de recherche',
  search_history: 'Récent',

  // Pluralizable values.
  post_plur_lc: 'aucun posts | {n} post | {n} posts',
  portfolio_plur_lc:
    'aucun portefeuille | {n} portefeuille | {n} portefeuilles',
  portfolio_items_plur: 'aucune ligne | {n} ligne | {n} lignes',
  view_more_replies_plur:
    'Aucune réponse | Afficher 1 réponse | Afficher {n} réponses',
  followers_plur_lc: 'Aucun Abonné | {n} Abonné | {n} Abonnés',

  money_format: ({ named }) => `${named('value')} ${named('currency')}`,
  money_format_without_currency: ({ named }) => `${named('value')}`,
  ordinal: ({ named }) => localizeOrdinal(named('locale'), named('value')),
  out_of: 'sur',

  date_format: 'DD/MM/YYYY',

  post_ai_translated: 'Traduit par IA',
  post_show_in_original_language: "Voir l'article non traduit",
  post_show_in_user_language: 'Voir la traduction',

  portfolio_limit_reached:
    'Vous avez atteint le nombre maximum de portefeuilles',
  you_have_no_portfolios: "Vous n'avez pas encore de portefeuille",

  this_page_doesnt_exist: "Oops. Cette page n'existe pas.",
  recent: 'Récent',
  clear: 'Effacer',
  see_all_results: 'Voir tous les résultats',
  empty_search_history: 'Recherchez des personnes, sociétés ou mots-clés',

  no_notifications: "Vous n'avez aucune notification",
  market_news: 'Actualités',

  on: 'sur le',
  user_comments: 'Commentaires',

  // ==============================================
  // Months
  // ==============================================
  january: 'Janvier',
  february: 'Février',
  march: 'Mars',
  april: 'Avril',
  may: 'Mai',
  june: 'Juin',
  july: 'Juillet',
  august: 'Août',
  september: 'Septembre',
  october: 'Octobre',
  november: 'Novembre',
  december: 'Décembre',

  edited_by_moderators: 'modifié par les modérateurs',
  followed_users_with_count: 'Utilisateurs suivis ({count})',
  followed_companies_with_count: 'Sociétés suivies ({count})',
  followed_crypto_with_count: 'Crypto suivies ({count})',
  followers_with_count: 'Abonnés ({count})',

  stocks_no_data_at_date_error:
    'Aucune donnée à cette date. Veuillez sélectionner une autre date.',
};
